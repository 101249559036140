// ** Redux **
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISectionsList } from 'modules/Calendar/types';
import { RootStateType } from 'reduxStore/store';

export interface ResourceState {
  students: ISectionsList[];
  teachers: ISectionsList[];
  services: ISectionsList[];
  classrooms: ISectionsList[];
  subjects: ISectionsList[];
}

type ResourceAction =
  | { type: 'SET_STUDENTS'; payload: ISectionsList[] }
  | { type: 'SET_TEACHERS'; payload: ISectionsList[] }
  | { type: 'SET_SERVICES'; payload: ISectionsList[] }
  | { type: 'SET_CLASSROOMS'; payload: ISectionsList[] }
  | { type: 'SET_SUBJECTS'; payload: ISectionsList[] };

const initialState: ResourceState = {
  students: [],
  teachers: [],
  services: [],
  classrooms: [],
  subjects: [],
};

const slice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    setResources(state: ResourceState, action: PayloadAction<ResourceAction>) {
      switch (action.payload.type) {
        case 'SET_STUDENTS':
          state.students = action.payload.payload;
          break;
        case 'SET_TEACHERS':
          state.teachers = action.payload.payload;
          break;
        case 'SET_SERVICES':
          state.services = action.payload.payload;
          break;
        case 'SET_CLASSROOMS':
          state.classrooms = action.payload.payload;
          break;
        case 'SET_SUBJECTS':
          state.subjects = action.payload.payload;
          break;
        default:
          break;
      }
    },
  },
});

export const { reducer } = slice;

export const { setResources } = slice.actions;

export const getResources = (state: RootStateType) => state.resources;

export default slice;
