import * as Yup from 'yup';

const EmailValidation = () => {
  return Yup.string()
    .email('Please enter a valid email address')
    .max(255, 'Maximum 255 characters allowed')
    .required('Please enter a valid email address')
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, 'Please enter a valid email address');
};

const PasswordValidation = () => {
  return Yup.string()
    .required('Please enter a valid password')
    .min(8, 'Minimum 8 characters required for password security')
    .matches(
      /(?=.*[a-z])(?=.*[A-Z])\w+/,
      'Upper and lower case alphabets are required'
    )
    .matches(/\d/, 'At least one number is required in the password')
    .matches(
      /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
      'At least one special character is required in the password'
    );
};
// Login Validation

export const LoginValidationSchema = () => {
  return Yup.object().shape({
    email: EmailValidation(),
    password: PasswordValidation(),
  });
};

// Forgot Password Validation

export const ForgotPasswordValidationSchema = () => {
  return Yup.object().shape({
    email: EmailValidation(),
  });
};

export const ResetPasswordValidationSchema = () => {
  return Yup.object().shape({
    password: PasswordValidation(),
    confirmPassword: Yup.string()
      .required(
        'Please confirm your password to ensure it matches the password entered above'
      )
      .oneOf(
        [Yup.ref('password')],
        'Passwords do not match, please re-enter to confirm'
      ),
  });
};
