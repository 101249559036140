import Button from 'components/Button/Button';
import CrossIcon from 'components/Icon/assets/CrossIcon';
import { MouseEvent, useEffect } from 'react';

interface ModalProps {
  parentClass?: string;
  overlayClass?: string;
  children?: JSX.Element | string;
  closeModal?: (e: MouseEvent<HTMLElement> | KeyboardEvent) => void;
  width?: string;
  title?: string;
  variant?: 'Confirmation' | null;
  icon?: JSX.Element | string;
  confirmationText?: string;
  hideFooterButton?: boolean;
  modalBodyClass?: string;
  modalBoxClass?: string;
  hideCrossIcon?: boolean;
  visible?: boolean;
  okButton?: boolean;
}

const Modal = (props: ModalProps) => {
  const {
    closeModal,
    parentClass,
    overlayClass,
    visible,
    width,
    modalBoxClass,
    variant,
    hideCrossIcon,
    title,
    icon,
    confirmationText,
    modalBodyClass,
    hideFooterButton,
    children,
    okButton,
  } = props;
  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.code === 'Escape' && closeModal) closeModal(e);
    };

    document.addEventListener('keydown', handler, true);
    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, [props]);
  return (
    <div
      className={`modal fixed top-0 left-0 w-full h-dvh z-4 flex items-center justify-center ${
        parentClass
      }`}
    >
      <div
        className={`modal-overlay fixed top-0 left-0 w-full h-dvh bg-[rgba(0,0,0,.5)] backdrop-blur-sm ${
          overlayClass
        }`}
        onClick={closeModal}
      />
      <div
        className={`modal-inner relative 1200:min-w-[400px] w-full max-h-[calc(100dvh_-_30px)]${
          visible ? '!overflow-visible' : ''
        } ${width ?? ' max-w-[700px]'}`}
      >
        <div className={`modal-box bg-white rounded-lg rounded-10 ${modalBoxClass}`}>
          {!variant && (
            <div className="modal-header flex justify-between items-center bg-primary text-white p-4 border-b border-slid border-gray-200 rounded-md gap-2">
              <h5 className="text-xl font-semibold">{title}</h5>
              {!hideCrossIcon && (
                <button onClick={closeModal}>
                  <CrossIcon />
                </button>
              )}
            </div>
          )}
          {variant === 'Confirmation' ? (
            <div className="relative mb-8">
              <div className="title text-center">
                <h2 className="font-bold text-primaryRed text-30px/9">{title}</h2>
              </div>
              <div className="icon my-20">
                <span className="w-24 h-24 bg-primaryRed/10 p-5 rounded-full block mx-auto text-primaryRed ring-2 ring-primaryRed/20 ring-offset-8">
                  {icon}
                </span>
              </div>

              <p className="max-w-[305px] mx-auto text-xl/7 text-grayDark text-center">
                {confirmationText}
              </p>
            </div>
          ) : (
            <div
              className={`modal-body mx-10 max-h-[calc(100dvh_-_210px)] noscroll ${
                props?.visible ? 'overflow-visible' : 'overflow-auto'
              }
                ${modalBodyClass}
                ${!hideFooterButton ? 'my-6' : 'mt-6'}`}
            >
              {children}
            </div>
          )}
          <div className="flex justify-center pb-1">
            {okButton ? (
              <Button variants="primary" onClickHandler={closeModal}>
                <span className="m-1">OK</span>
              </Button>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
