import { IconInputProps } from '../types/icons';

const TeachworksIcon = ({ className }: IconInputProps) => {
  return (
    <svg
      width="83"
      height="12"
      viewBox="0 0 83 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? ''}
    >
      <path
        d="M5.80653 10.8442C5.32684 11.0489 4.8063 11.1399 4.28561 11.11C2.67904 11.11 1.87575 10.2762 1.87575 8.60857V5.22709H0.679688V3.54669H1.87575V1.95194L4.14681 1.30518V3.54669H5.80653V5.22709H4.14681V8.21283C4.14681 8.98068 4.44213 9.3646 5.06231 9.3646C5.32433 9.36012 5.58072 9.28788 5.80653 9.15492V10.8442Z"
        fill="#122634"
      />
      <path
        d="M13.6737 7.8881H8.84813C8.92491 8.95915 9.60022 9.49467 10.8741 9.49467C11.6295 9.50622 12.3725 9.30126 13.0152 8.90402V10.546C12.1875 10.9439 11.2748 11.1325 10.3572 11.0953C9.17594 11.0953 8.25059 10.7646 7.58118 10.103C6.91966 9.45923 6.58594 8.53487 6.58594 7.35356C6.58594 6.12698 6.94131 5.15635 7.65206 4.44166C7.99204 4.09131 8.40073 3.81497 8.85252 3.62999C9.30431 3.44501 9.78944 3.35536 10.2775 3.36667C11.3525 3.36667 12.1853 3.68661 12.776 4.32648C13.3666 4.96635 13.6659 5.83855 13.6737 6.94306V7.8881ZM11.5592 6.48826C11.5592 5.431 11.1339 4.90237 10.2775 4.90237C10.0956 4.90267 9.91601 4.94367 9.75198 5.02237C9.58795 5.10106 9.4436 5.21546 9.32951 5.35717C9.05593 5.67593 8.88596 6.07048 8.84222 6.48826H11.5592Z"
        fill="#122634"
      />
      <path
        d="M21.1631 10.93H19.0072V9.86974H18.9895C18.7737 10.26 18.4537 10.5827 18.0652 10.8017C17.6767 11.0207 17.235 11.1275 16.7893 11.1101C16.4814 11.1269 16.1731 11.0818 15.883 10.9773C15.5928 10.8728 15.3266 10.7111 15.1001 10.5017C14.8913 10.2871 14.7289 10.032 14.6227 9.75211C14.5166 9.47222 14.4689 9.17352 14.4828 8.87449C14.4828 7.44315 15.3314 6.61624 17.0285 6.39376L19.0338 6.12797C19.0338 5.32271 18.5957 4.9191 17.7196 4.91713C16.8241 4.92287 15.9509 5.19647 15.2123 5.7027V3.98981C15.6275 3.79353 16.0647 3.64778 16.5147 3.55568C17.0073 3.43837 17.5115 3.37597 18.0179 3.36963C20.1206 3.36963 21.1729 4.42 21.1749 6.52075L21.1631 10.93ZM19.022 7.92945V7.4333L17.6812 7.60755C16.9409 7.70205 16.5708 8.03577 16.5708 8.6087C16.5668 8.72813 16.5888 8.847 16.6352 8.95712C16.6816 9.06725 16.7513 9.16601 16.8395 9.2466C17.0428 9.42013 17.305 9.50893 17.5719 9.49467C17.7679 9.50121 17.963 9.46497 18.1435 9.38847C18.3241 9.31196 18.4858 9.19704 18.6174 9.05169C18.8913 8.7434 19.0361 8.34159 19.022 7.92945Z"
        fill="#122634"
      />
      <path
        d="M28.5622 10.6642C28.0483 10.9615 27.3051 11.1101 26.3325 11.1101C25.828 11.1322 25.3242 11.0522 24.8513 10.8751C24.3783 10.698 23.946 10.4274 23.58 10.0794C23.23 9.7334 22.9556 9.31852 22.7742 8.86103C22.5927 8.40353 22.5081 7.91337 22.5257 7.42151C22.5257 6.16737 22.9018 5.18295 23.6539 4.46826C24.406 3.75358 25.411 3.38639 26.6691 3.3667C27.5394 3.3667 28.1694 3.48188 28.5592 3.71223V5.64366C28.101 5.29508 27.5402 5.10815 26.9645 5.11207C26.6802 5.09747 26.396 5.14073 26.129 5.23921C25.862 5.33769 25.6177 5.48935 25.411 5.685C25.2135 5.89645 25.061 6.14574 24.9625 6.4178C24.864 6.68987 24.8217 6.97907 24.8381 7.26795C24.8222 7.54811 24.8626 7.82861 24.957 8.09288C25.0513 8.35716 25.1977 8.59984 25.3874 8.80659C25.5865 8.99827 25.8231 9.14677 26.0823 9.24282C26.3414 9.33886 26.6176 9.38037 26.8936 9.36475C27.4891 9.35595 28.0687 9.17098 28.5592 8.83317L28.5622 10.6642Z"
        fill="#122634"
      />
      <path
        d="M37.1386 10.93H34.8675V6.73339C34.8675 5.6525 34.4718 5.11206 33.6862 5.11206C33.4986 5.10605 33.3123 5.14406 33.142 5.22303C32.9717 5.30201 32.8223 5.41976 32.7058 5.56686C32.4465 5.89332 32.3134 6.30213 32.3307 6.71862V10.93H30.0508V0.00292969H32.3307V4.64249H32.3573C32.5904 4.24642 32.9247 3.9195 33.3259 3.6953C33.727 3.4711 34.1806 3.35768 34.6401 3.36668C36.3156 3.36668 37.1524 4.37571 37.1504 6.39377L37.1386 10.93Z"
        fill="#122634"
      />
      <path
        d="M49.6371 3.54639L47.5107 10.9295H45.1186L44.0288 6.603C43.9549 6.29313 43.9133 5.97642 43.9048 5.65796H43.8635C43.8395 5.96648 43.7911 6.2726 43.7187 6.57347L42.5374 10.9295H40.1748L38.0898 3.54639H40.4111L41.4359 8.36314C41.4918 8.63385 41.5283 8.90822 41.5451 9.18414H41.5894C41.6043 8.89701 41.6478 8.61208 41.7194 8.33361L42.9863 3.54639H45.157L46.2969 8.36314C46.3459 8.63964 46.3795 8.91867 46.3973 9.19891H46.4564C46.4803 8.91851 46.5187 8.63955 46.5716 8.36314L47.5314 3.54639H49.6371Z"
        fill="#122634"
      />
      <path
        d="M54.1644 11.1101C52.9339 11.1101 51.9662 10.7656 51.2614 10.0765C50.5565 9.3874 50.2051 8.45122 50.207 7.26795C50.207 6.0453 50.5723 5.08943 51.3027 4.40034C52.0331 3.71125 53.0176 3.3667 54.2559 3.3667C55.4825 3.3667 56.4443 3.71125 57.1413 4.40034C57.4936 4.76082 57.7682 5.1898 57.948 5.6607C58.1279 6.13161 58.2091 6.63442 58.1867 7.138C58.1867 8.36459 57.8274 9.33325 57.1088 10.044C56.3902 10.7548 55.4087 11.1101 54.1644 11.1101ZM54.2235 5.11207C53.9853 5.09906 53.7474 5.14258 53.5293 5.23907C53.3111 5.33557 53.1189 5.4823 52.9683 5.66728C52.644 6.12441 52.4866 6.67907 52.5224 7.23841C52.5224 8.65597 53.0943 9.36475 54.2382 9.36475C55.327 9.36475 55.8724 8.63629 55.8743 7.17935C55.8743 5.80116 55.324 5.11207 54.2235 5.11207Z"
        fill="#122634"
      />
      <path
        d="M64.4886 5.60223C64.1939 5.44728 63.8646 5.37003 63.5318 5.37779C63.3085 5.36844 63.0862 5.41293 62.8837 5.50751C62.6812 5.60208 62.5044 5.74396 62.3682 5.92118C62.0708 6.35423 61.9245 6.87321 61.9518 7.39781V10.9299H59.6719V3.54677H61.9518V4.91708H61.9813C62.3416 3.91593 62.9884 3.41683 63.9275 3.41683C64.1183 3.40882 64.3089 3.4379 64.4886 3.50247V5.60223Z"
        fill="#122634"
      />
      <path
        d="M72.9477 10.93H70.216L67.8534 7.26793H67.8268V10.93H65.5469V0.00292969H67.8268V6.95784H67.8534L70.0535 3.54683H72.7587L70.1008 7.01395L72.9477 10.93Z"
        fill="#122634"
      />
      <path
        d="M73.33 10.7351V8.88934C73.6803 9.10611 74.0577 9.2759 74.4522 9.39434C74.794 9.49944 75.149 9.55513 75.5065 9.55972C75.8337 9.57358 76.1599 9.51718 76.4634 9.39434C76.5666 9.3565 76.6555 9.28763 76.7179 9.19719C76.7803 9.10676 76.8131 8.99921 76.8119 8.88934C76.8132 8.82128 76.7996 8.75377 76.772 8.69153C76.7445 8.62929 76.7037 8.57383 76.6524 8.52904C76.5326 8.4218 76.3946 8.33679 76.2449 8.27801C76.0686 8.2001 75.887 8.13496 75.7015 8.0831C75.5036 8.02403 75.3146 7.95906 75.1315 7.88818C74.8622 7.78965 74.6006 7.67119 74.3489 7.53379C74.1385 7.41711 73.948 7.26756 73.7848 7.09081C73.6323 6.91824 73.515 6.71762 73.4393 6.50015C73.3552 6.24701 73.3152 5.98128 73.3211 5.71459C73.3104 5.3412 73.4035 4.97216 73.5899 4.64847C73.7705 4.35116 74.0184 4.10032 74.3134 3.91606C74.6301 3.7175 74.9788 3.57559 75.3441 3.4967C75.7405 3.40701 76.1457 3.36242 76.552 3.3638C76.8852 3.36338 77.218 3.38906 77.5472 3.44059C77.8835 3.49132 78.2159 3.56432 78.5425 3.65913V5.43108C78.2526 5.26228 77.9405 5.13503 77.6152 5.05306C77.2981 4.9694 76.9715 4.92673 76.6436 4.92607C76.5028 4.92484 76.3623 4.9377 76.2242 4.96446C76.1025 4.98718 75.9843 5.02591 75.8728 5.07964C75.779 5.12394 75.6978 5.19094 75.6365 5.27456C75.578 5.35295 75.5468 5.44842 75.5479 5.54625C75.5463 5.67365 75.5927 5.79698 75.6778 5.89178C75.7742 5.99536 75.8895 6.07956 76.0175 6.13986C76.1671 6.21428 76.3221 6.27746 76.4811 6.32887C76.6524 6.38202 76.8237 6.44109 76.992 6.4972C77.2728 6.59468 77.5471 6.71009 77.813 6.84273C78.0434 6.9588 78.2562 7.10695 78.445 7.28277C78.6187 7.44941 78.7556 7.65062 78.8467 7.87342C78.9468 8.13116 78.995 8.40617 78.9884 8.68261C78.9986 9.07621 78.8961 9.46453 78.6931 9.80189C78.5062 10.1123 78.2468 10.3728 77.9371 10.5609C77.601 10.7643 77.2323 10.9081 76.8473 10.9861C76.427 11.0758 75.9983 11.1204 75.5686 11.119C74.8057 11.1221 74.0482 10.9922 73.33 10.7351Z"
        fill="#122634"
      />
      <path
        d="M81.3037 11.11C80.9587 11.1201 80.6232 10.9963 80.3675 10.7644C80.2471 10.6583 80.1512 10.5273 80.0864 10.3804C80.0217 10.2336 79.9896 10.0744 79.9925 9.91389C79.9888 9.75214 80.0217 9.59165 80.0887 9.44439C80.1556 9.29712 80.255 9.16687 80.3793 9.06335C80.6479 8.83688 80.991 8.71798 81.3421 8.72963C81.6915 8.71549 82.0329 8.836 82.296 9.0663C82.4143 9.17476 82.5088 9.30664 82.5735 9.45357C82.6381 9.6005 82.6715 9.75927 82.6715 9.9198C82.6715 10.0803 82.6381 10.2391 82.5735 10.386C82.5088 10.5329 82.4143 10.6648 82.296 10.7733C82.0196 11.0068 81.6651 11.1271 81.3037 11.11Z"
        fill="#122634"
      />
    </svg>
  );
};

export default TeachworksIcon;
