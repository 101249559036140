import { format } from 'date-fns';
import { IEvent } from 'interfaces/common.interface';

export const DropdownLoaderTypes = {
  Skeleton: 'Skeleton',
  Default: 'Default',
};

export const getCalendarTooltip = (start: Date, _end: Date, event: IEvent) => {
  return `
    <div style="font-family: Montserrat, sans-serif; background-color: white; font-weight: 400; border-radius: 10px; box-shadow: 0 4px 6px rgba(0,0,0,0.1); width: 300px; overflow: hidden; min-width: max-content;">
      <div style="background-color: #fce4d6; font-weight: 500; padding: 10px; display: flex; flex-direction: column; gap: 4;">
        <span style="max-width: 400px; font-size: 18px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
          ${event.text}
        </span>
        <span style="color: #666;">
          ${format(start, 'eeee, MMMM d')}
        </span> 
      </div>
      <div style="padding: 16px 10px; display: flex; flex-direction: column; gap: 10px;">
        ${event.students
          ?.map(
            (student) => `
            <div style="display: flex; align-items: center; justify-content: space-between;">
              <span style="display: flex; align-items: center;">
                <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" style="margin-right: 10px;">
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
                <span>
                  <span style="font-weight: 500;">Student Name:</span>
                  <span>${student.name}</span>
                </span>
                </span>
                <span style="width: 10px; height: 10px; background-color: ${student.color ?? '#d35400'}; border-radius: 50%;"></span>
            </div>`
          )
          .join(' ')}
        <div style="display: flex; align-items: center; justify-content: space-between;">
          <span style="display: flex; align-items: center;">
            <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" style="margin-right: 10px;">
              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
            </svg>
            <span><span style="font-weight: 500;">Teacher Name:</span> ${event.teacher ?? 'Unassigned'}</span>
          </span>
          <span style="width: 10px; height: 10px; background-color: ${event.teacherColor ?? '#27ae60'}; border-radius: 50%;"></span>
        </div>
        <div style="display: flex; align-items: center; justify-content: space-between;">
          <span style="display: flex; align-items: center;">
            <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" style="margin-right: 10px;">
              <circle cx="12" cy="12" r="10"></circle>
              <path d="M12 8v4l3 3"></path>
            </svg>
            <span><span style="font-weight: 500;">Location:</span> ${event.classroom}</span>
          </span>
          <span style="width: 10px; height: 10px; background-color: ${event.classroomColor ?? '#e74c3c'}; border-radius: 50%;"></span>
        </div>
        <div style="display: flex; align-items: center;">
          <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" style="margin-right: 10px;">
            <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path>
            <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path>
          </svg>
          <span><span style="font-weight: 500;">Course:</span> ${event.course}</span>
        </div>
        <div style="display: flex; align-items: center;">
          <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" style="margin-right: 10px;">
            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
            <line x1="16" y1="2" x2="16" y2="6"></line>
            <line x1="8" y1="2" x2="8" y2="6"></line>
            <line x1="3" y1="10" x2="21" y2="10"></line>
          </svg>
          <span><span style="font-weight: 500;">Session:</span> ${event.sessionNumber ?? 5} of ${event.totalSessions ?? 30}</span>
        </div>
      </div>
    </div>`;
};

export const getCalendarFilterButton = () => {
  return `<svg
                    width="20"
                    height="23"
                    viewBox="0 0 20 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M18.3038 4.61727C18.1813 4.31274 17.9811 4.0539 17.7274 3.8722C17.4737 3.6905 17.1776 3.59376 16.8748 3.59375H3.12485C2.82219 3.59378 2.52605 3.69051 2.27242 3.87217C2.01879 4.05383 1.81858 4.31261 1.69612 4.61707C1.57365 4.92152 1.53421 5.25855 1.58259 5.5872C1.63096 5.91584 1.76506 6.22196 1.9686 6.46836L1.97797 6.48039L7.18735 12.5957V19.0625C7.18731 19.3737 7.26406 19.679 7.40942 19.946C7.55478 20.2129 7.76329 20.4314 8.01272 20.5782C8.26214 20.725 8.54311 20.7946 8.82566 20.7795C9.1082 20.7644 9.38172 20.6652 9.61704 20.4925L12.117 18.6595C12.3311 18.5024 12.5065 18.2896 12.6278 18.0399C12.7491 17.7903 12.8125 17.5116 12.8123 17.2286V12.5957L18.0209 6.48039L18.0303 6.46836C18.234 6.22209 18.3682 5.91606 18.4168 5.58745C18.4653 5.25884 18.4261 4.92179 18.3038 4.61727ZM11.36 11.2843C11.088 11.6025 10.9368 12.0231 10.9373 12.4599V17.0447L9.06235 18.4197V12.4599C9.06291 12.0231 8.91171 11.6025 8.63969 11.2843L3.84594 5.65625H16.1538L11.36 11.2843Z"
                        fill="#585B5D"
                        fill-opacity="0.5"/>
                    </svg>`;
};
