import App from 'App';
import { setupAxios } from 'base-axios';
import Toast from 'components/Toast';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './index.css';
import store, { persistor } from './reduxStore/store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

setupAxios(store);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Toast />
      <App />
    </PersistGate>
  </Provider>
);
