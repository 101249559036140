import Button from 'components/Button/Button';
import InputField from 'components/FormElement/InputField';
import Icon from 'components/Icon';
import { REACT_APP_API_URL } from 'config';
import { Form, Formik, FormikValues } from 'formik';
import { useAxiosPost } from 'hooks/useAxios';
import { ResetPasswordValidationSchema } from 'modules/Auth/validationSchema';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [callApi, { isLoading }] = useAxiosPost();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const resetPasswordInitialValue = { password: '', confirmPassword: '' };

  const OnSubmit = async (userData: FormikValues) => {
    if (userData) {
      const resetPasswordData = {
        password: userData.password,
        token,
      };
      const response = await callApi(
        `${REACT_APP_API_URL}/auth/resetPassword`,
        resetPasswordData,
        {}
      );
      if (response.data) {
        navigate('/auth/login');
      }
    }
  };

  return (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded shadow-md w-[350px]">
      <h2 className="text-2xl font-semibold mb-6 text-center">Reset Password</h2>
      <Formik
        initialValues={resetPasswordInitialValue}
        validationSchema={ResetPasswordValidationSchema()}
        onSubmit={(values) => OnSubmit(values)}
      >
        <Form>
          <InputField
            name="password"
            label="Password"
            labelClass="block text-gray-700 text-sm font-bold mb-2"
            placeholder="Enter your Password"
            type={showPassword ? 'text' : 'password'}
            icon={
              <Button
                className="cursor-pointer w-5 h-5 text-grayText"
                onClickHandler={() => setShowPassword(!showPassword)}
              >
                <Icon
                  className="w-full h-full"
                  name={showPassword ? 'eyeIcon' : 'eyeCrossIcon'}
                />
              </Button>
            }
          />
          <InputField
            name="confirmPassword"
            label="Confirm Password"
            labelClass="block text-gray-700 text-sm font-bold mb-2"
            placeholder="Enter your Password Again"
            type={showConfirmPassword ? 'text' : 'password'}
            icon={
              <Button
                className="cursor-pointer w-5 h-5 text-grayText"
                onClickHandler={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                <Icon
                  className="w-full h-full"
                  name={showConfirmPassword ? 'eyeIcon' : 'eyeCrossIcon'}
                />
              </Button>
            }
          />
          <div className="flex my-4 w-full gap-2 justify-center col-span-2">
            <Button
              isLoading={isLoading}
              variants="primary"
              className="w-full min-w-[150px] justify-center"
              type="submit"
              value="Submit"
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default ResetPassword;
