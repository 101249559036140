import { IconInputProps } from '../types/icons';

const GreaterThanIcon = ({ className }: IconInputProps) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.9722 9.59717L7.34717 15.2222C7.18866 15.3807 6.97367 15.4697 6.74951 15.4697C6.52535 15.4697 6.31036 15.3807 6.15185 15.2222C5.99335 15.0637 5.9043 14.8487 5.9043 14.6245C5.9043 14.4003 5.99335 14.1854 6.15185 14.0269L10.1191 10.0607C10.705 9.47502 10.7051 8.52527 10.1194 7.9394L6.15326 3.97217C6.07478 3.89368 6.01252 3.80051 5.97004 3.69796C5.92757 3.59541 5.9057 3.48551 5.9057 3.37451C5.9057 3.26352 5.92757 3.15361 5.97004 3.05106C6.01252 2.94852 6.07478 2.85534 6.15326 2.77685C6.23175 2.69837 6.32492 2.63611 6.42747 2.59364C6.53001 2.55116 6.63992 2.5293 6.75092 2.5293C6.86191 2.5293 6.97182 2.55116 7.07437 2.59364C7.17691 2.63611 7.27009 2.69837 7.34857 2.77685L12.9736 8.40185C13.0521 8.48033 13.1144 8.57355 13.1569 8.67616C13.1994 8.77877 13.2212 8.88875 13.221 8.9998C13.2209 9.11085 13.1989 9.22078 13.1561 9.32329C13.1134 9.4258 13.0509 9.51887 12.9722 9.59717Z"
        fill="#D16E1C"
      />
    </svg>
  );
};

export default GreaterThanIcon;
