import Button from 'components/Button/Button';
import SiteLoader from 'components/Loaders/SiteLoader';
import { useAxiosPost } from 'hooks/useAxios';
import { events, LoaderStatusEnum, StatusEnum } from 'interfaces/common.interface';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  currentInstituteSelector,
  processIdSelector,
} from 'reduxStore/slices/instituteSlice';
import {
  getLoaderStatus,
  setLoaderStatus,
  setLoaderStatusMessage,
  setPrimaryStep,
} from 'reduxStore/slices/loaderSlice';
import { IDataError, IErrorSection, IMissingData } from '../types';
import CollapsableMenu from './CollapsableMenu';

export default function Validation() {
  const [callPostApi, { isLoading }] = useAxiosPost();
  const currentInstitute = useSelector(currentInstituteSelector);
  const processId = useSelector(processIdSelector);
  const loaderStatus = useSelector(getLoaderStatus);
  const dispatch = useDispatch();
  const [openSections, setOpenSections] = useState<Record<string, boolean>>({});
  const [primaryDataErrors, setPrimaryDataErrors] = useState<IDataError[]>([]);
  const [stats, setStats] = useState<{ value: string; label: string }[]>([]);
  const [errorSections, setErrorSections] = useState<IErrorSection[]>([]);
  const [secondaryDataErrors, setSecondaryDataErrors] = useState<IDataError[]>([]);
  const [missingData, setMissingData] = useState<IMissingData[]>([]);

  const toggleSection = (sectionId: string) => {
    setOpenSections((prev) => ({
      ...prev,
      [sectionId]: !prev[sectionId],
    }));
  };

  const fetchValidationData = async () => {
    const { data } = await callPostApi(
      '/schedule-process-logs/constraint-validation',
      {
        instituteId: currentInstitute?.value,
        processId,
        toggleOption: 'on',
        uniform_distributivity: true,
        infeasibility_diagnosis: true,
        start_date: '2024-12-05',
        take_holidays_into_account: false,
        default_course_length: 31,
        take_hws_into_account: false,
        second_logic: false,
      }
    );

    if (data) {
      const { primary_data_validation, secondary_data_validation } = data;
      setPrimaryDataErrors(
        [
          {
            id: 'subjectsMissingDisplayName',
            label: 'Display name is missing from Subjects',
            items:
              primary_data_validation?.subject?.subjectsMissingDisplayName ?? [],
          },
          {
            id: 'subjectsMissingDefaultSessions',
            label: 'Default sessions are missing from Subjects',
            items:
              primary_data_validation?.subject?.subjectsMissingDefaultSessions ?? [],
          },
          {
            id: 'studentsMissingStartDate',
            label: 'Start date is missing from Students',
            items: primary_data_validation?.student?.studentsMissingStartDate ?? [],
          },
          {
            id: 'studentsMissingEndDate',
            label: 'End date is missing from Students',
            items: primary_data_validation?.student?.studentsMissingEndDate ?? [],
          },
          {
            id: 'studentsMissingBothDates',
            label: 'Start date and end dates are missing from Teachers',
            items: primary_data_validation?.student?.studentsMissingBothDates ?? [],
          },
          {
            id: 'studentsMissingAvailabilities',
            label: 'Availabilities are missing from Students',
            items:
              primary_data_validation?.student?.studentsMissingAvailabilities ?? [],
          },
          {
            id: 'studentsMissingFirstName',
            label: 'First name is missing from Students',
            items: primary_data_validation?.student?.studentsMissingFirstName ?? [],
          },
          {
            id: 'studentsMissingLastName',
            label: 'Last name is missing from Students',
            items: primary_data_validation?.student?.studentsMissingLastName ?? [],
          },
          {
            id: 'studentsMissingBothNames',
            label: 'Both first name and last names are missing from Students',
            items: primary_data_validation?.student?.studentsMissingBothNames ?? [],
          },
          {
            id: 'studentsMissingServiceId',
            label: 'Service id is missing from Students',
            items: primary_data_validation?.student?.studentsMissingServiceId ?? [],
          },
          {
            id: 'studentsMissingSubjects',
            label: 'Subjects are missing from Students',
            items: primary_data_validation?.student?.studentsMissingSubjects ?? [],
          },
          {
            id: 'teachersMissingStartDate',
            label: 'Start date is missing from Teachers',
            items: primary_data_validation?.teacher?.teachersMissingStartDate ?? [],
          },
          {
            id: 'teachersMissingEndDate',
            label: 'End date is missing from Teachers',
            items: primary_data_validation?.teacher?.teachersMissingEndDate ?? [],
          },
          {
            id: 'teachersMissingBothDates',
            label: 'Start date and end dates are missing from Teachers',
            items: primary_data_validation?.teacher?.teachersMissingBothDates ?? [],
          },
          {
            id: 'teachersMissingAvailabilities',
            label: 'Availabilities are missing from Teachers',
            items:
              primary_data_validation?.teacher?.teachersMissingAvailabilities ?? [],
          },
          {
            id: 'teachersMissingFirstName',
            label: 'First name is missing from Teachers',
            items: primary_data_validation?.teacher?.teachersMissingFirstName ?? [],
          },
          {
            id: 'teachersMissingLastName',
            label: 'Last name is missing from Teachers',
            items: primary_data_validation?.teacher?.teachersMissingLastName ?? [],
          },
          {
            id: 'teachersMissingBothNames',
            label: 'Both first name and last names are missing from Teachers',
            items: primary_data_validation?.teacher?.teachersMissingBothNames ?? [],
          },
          {
            id: 'teachersMissingSubjects',
            label: 'Subjects are missing from Teachers',
            items: primary_data_validation?.teacher?.teachersMissingSubjects ?? [],
          },
          ...Object.keys(primary_data_validation?.ciplex).map((key) => {
            const value = primary_data_validation?.ciplex[key];
            return {
              id: key,
              label: value.description,
              items: value.data,
            };
          }),
        ].sort((a, b) => b.items.length - a.items.length)
      );
      setSecondaryDataErrors([
        {
          id: 'studentsMissingSubjects',
          label: 'Subjects are missing from Students',
          items: secondary_data_validation?.student?.studentsMissingSubjects,
        },
        {
          id: 'studentsMissingGenderPreference',
          label: 'Gender preference is missing from Students',
          items: secondary_data_validation?.student?.studentsMissingGenderPreference,
        },
        {
          id: 'studentsMissingTeacherRestriction',
          label: 'Teacher restriction is missing from Students',
          items:
            secondary_data_validation?.student?.studentsMissingTeacherRestriction,
        },
        {
          id: 'teachersMissingGenders',
          label: 'Gender is missing from Teachers',
          items: secondary_data_validation?.teacher?.teachersMissingGenders,
        },
        {
          id: 'teachersMissingGenderPreference',
          label: 'Gender preference is missing from Teachers',
          items: secondary_data_validation?.teacher?.teachersMissingGenderPreference,
        },
      ]);
      setStats([
        {
          label: 'Numbers of Student',
          value: primary_data_validation?.student?.totalStudentCount,
        },
        {
          label: 'Numbers of Teachers',
          value: primary_data_validation?.teacher?.totalTeacherCount,
        },
        {
          label: 'Total Number of Courses',
          value: primary_data_validation?.subject?.totalSubjectCount,
        },
        {
          label: 'Numbers of Classroom',
          value: primary_data_validation?.location?.totalClassroomCount,
        },
      ]);
      setErrorSections([
        {
          type: 'primary',
          count: primary_data_validation?.totalErrorCount,
          label: 'Primary Data Error',
          description: 'This Data should be solved before syncing',
        },
        {
          type: 'secondary',
          count: secondary_data_validation?.totalErrorCount,
          label: 'Secondary Data Error',
          description: 'This Data will be optional to solved before syncing',
        },
      ]);
      setMissingData([
        {
          id: 'missingSubjectData',
          label: 'Subjects Data is Missing',
          isMissing: primary_data_validation?.subject?.missingSubjectData,
        },
        {
          id: 'missingStudentData',
          label: 'Students Data is Missing',
          isMissing: primary_data_validation?.student?.missingStudentData,
        },
        {
          id: 'missingTeacherData',
          label: 'Teachers Data is Missing',
          isMissing: primary_data_validation?.teacher?.missingTeacherData,
        },
        {
          id: 'missingClassroomData',
          label: 'Classrooms Data is Missing',
          isMissing: primary_data_validation?.location?.missingClassroomData,
        },
        {
          id: 'missingHolidayData',
          label: 'Holidays Data is Missing',
          isMissing: primary_data_validation?.holiday?.missingHolidayData,
        },
      ]);
    }
  };

  const handleSubmit = async () => {
    const response = await callPostApi('/schedule-process-logs/call-ciplex', {
      instituteId: currentInstitute?.value,
      processId,
      toggleOption: 'on',
      uniform_distributivity: true,
      infeasibility_diagnosis: true,
      start_date: '2024-09-01',
      take_holidays_into_account: false,
      default_course_length: 31,
      take_hws_into_account: false,
      second_logic: false,
    });

    if (response.response_type === StatusEnum.SUCCESS) {
      dispatch(setLoaderStatusMessage('Preparing optimized schedule...'));
      dispatch(setLoaderStatus(LoaderStatusEnum.IN_PROGRESS));
      dispatch(setPrimaryStep(events.CIPLEX_CALL.id));
    }
  };

  const handleReSync = async () => {
    await callPostApi('/schedule-process-logs', {
      instituteId: currentInstitute?.value,
    });
    dispatch(setLoaderStatusMessage('Syncing...'));
    dispatch(setLoaderStatus(LoaderStatusEnum.CREATE));
    dispatch(setPrimaryStep(0));
  };

  useEffect(() => {
    fetchValidationData();
  }, []);

  return (
    <>
      {isLoading && <SiteLoader />}
      <div className="w-full max-w-4xl mx-auto p-4 space-y-4">
        <div className="flex gap-[10px] justify-center">
          <Button
            className={`bg-primary text-white rounded-[6px] px-[16px] py-[8px] font-600 text-[12px] ${loaderStatus === LoaderStatusEnum.COMPLETE ? 'cursor-not-allowed' : 'cursor-pointer'}`}
            onClickHandler={() => {
              if (loaderStatus !== LoaderStatusEnum.COMPLETE) {
                handleSubmit();
              }
            }}
          >
            Submit
          </Button>
          <Button
            className="text-primary bg-primaryLight-30 border border-primaryLight-20 rounded-[6px] px-[16px] py-[8px] font-600 text-[12px] cursor-pointer hover:bg-primaryLight-40"
            onClickHandler={handleReSync}
          >
            Re-Sync
          </Button>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {stats.map((stat) => (
            <div
              key={stat.label}
              className="p-4 rounded-lg border border-gray-200 bg-white text-center space-y-1"
            >
              <div className="text-2xl font-semibold">{stat.value}</div>
              <div className="text-sm text-gray-500">{stat.label}</div>
            </div>
          ))}
        </div>

        <div className="grid gap-4 md:grid-cols-2">
          {errorSections.map((section) => (
            <div
              key={section.type}
              className={`p-4 rounded-lg ${
                section.type === 'primary' ? 'bg-red-50' : 'bg-orange-50'
              } border ${section.type === 'primary' ? 'border-red-200' : 'border-orange-200'}`}
            >
              <div className="flex items-start gap-2">
                <div
                  className={`w-2 h-2 mt-2 rounded-full ${
                    section.type === 'primary' ? 'bg-red-500' : 'bg-orange-500'
                  }`}
                />
                <div>
                  <div className="font-semibold">
                    {section.count} {section.label}
                  </div>
                  <div className="text-sm text-gray-600">{section.description}</div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="space-y-2">
          {missingData.map(
            (section) =>
              section.isMissing && (
                <span
                  key={section.label}
                  className="w-full px-4 py-3 flex items-center border rounded-md justify-between hover:bg-gray-50 text-red-500 text-sm"
                >
                  {section.label}
                </span>
              )
          )}
          {primaryDataErrors.map((section) => (
            <CollapsableMenu
              key={section.label}
              section={section}
              toggleSection={toggleSection}
              openSections={openSections}
            />
          ))}
          {secondaryDataErrors.map((section) => (
            <CollapsableMenu
              key={section.label}
              section={section}
              toggleSection={toggleSection}
              openSections={openSections}
              type="secondary"
            />
          ))}
        </div>
      </div>
    </>
  );
}
