import Header from 'components/Layout/components/Header';
import SocketComponent from 'components/Socket/SocketComponent';
import { PRIVATE_NAVIGATION } from 'constants/navigation.constant';
import { LoaderStatusEnum } from 'interfaces/common.interface';
import DataValidation from 'modules/DataValidation';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getLoaderStatus } from 'reduxStore/slices/loaderSlice';

type Props = {
  children: React.ReactNode;
};

const Layout = (props: Props) => {
  const { children } = props;
  const navigate = useNavigate();
  const loaderStatus = useSelector(getLoaderStatus);
  const { pathname } = useLocation();

  useEffect(() => {
    if (
      loaderStatus === LoaderStatusEnum.NOT_STARTED ||
      loaderStatus === LoaderStatusEnum.FINISH ||
      loaderStatus === LoaderStatusEnum.FAIL ||
      loaderStatus === LoaderStatusEnum.INFEASIBLE
    ) {
      if (pathname !== PRIVATE_NAVIGATION.scheduleGeneration.path) {
        navigate(PRIVATE_NAVIGATION.scheduleGeneration.path);
      }
    } else if (
      loaderStatus !== LoaderStatusEnum.COMPLETE &&
      pathname === PRIVATE_NAVIGATION.calendar.path
    ) {
      navigate(PRIVATE_NAVIGATION.dataValidation.path);
    }
  }, [loaderStatus, pathname]);

  return (
    <div className="flex h-screen">
      <SocketComponent />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header />
        <main className="flex-1 p-0 overflow-auto">
          {loaderStatus === LoaderStatusEnum.NOT_STARTED ||
          loaderStatus === LoaderStatusEnum.COMPLETE ||
          loaderStatus === LoaderStatusEnum.PENDING ||
          loaderStatus === LoaderStatusEnum.FINISH ||
          loaderStatus === LoaderStatusEnum.FAIL ||
          loaderStatus === LoaderStatusEnum.INFEASIBLE ? (
            children
          ) : (
            <DataValidation />
          )}
        </main>
      </div>
    </div>
  );
};

export default Layout;
