import { REACT_APP_API_URL } from 'config';
import { PRIVATE_NAVIGATION } from 'constants/navigation.constant';
import { events, LoaderStatusEnum, StatusEnum } from 'interfaces/common.interface';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  currentInstituteSelector,
  setProcessId,
} from 'reduxStore/slices/instituteSlice';
import {
  setLoaderState,
  setLoaderStatus,
  setLoaderStatusMessage,
  setPrimaryStep,
  setProgressStatus,
} from 'reduxStore/slices/loaderSlice';
import { setToast } from 'reduxStore/slices/toastSlice';
import { getAuthToken } from 'reduxStore/slices/tokenSlice';
import openSocket from 'socket.io-client';

const SocketComponent = () => {
  const dispatch = useDispatch();
  const currentInstitute = useSelector(currentInstituteSelector);
  const token = useSelector(getAuthToken);
  const navigate = useNavigate();
  const [navigateTo, setNavigateTo] = useState('');

  useEffect(() => {
    navigate(navigateTo);
  }, [navigateTo]);

  const handleCompleteProcessOfScheduleGeneration = async (data: string) => {
    const { status, instituteId, processId } = JSON.parse(data);
    if (instituteId === currentInstitute?.value) {
      dispatch(setProcessId(processId));
      if (status === StatusEnum.PENDING) {
        dispatch(setLoaderState(true));
      } else if (status === StatusEnum.SUCCESS) {
        dispatch(setLoaderState(false));
        dispatch(setLoaderStatus(LoaderStatusEnum.COMPLETE));
        setNavigateTo(PRIVATE_NAVIGATION.calendar.path);
      } else {
        dispatch(setProcessId(''));
        dispatch(setLoaderState(false));
        dispatch(setLoaderStatus(LoaderStatusEnum.FAIL));
      }
    }
  };

  const handleSyncingStatus = async (
    data: string,
    obj?: { id: number; message: string }
  ) => {
    const { status, instituteId } = JSON.parse(data);
    if (instituteId === currentInstitute?.value) {
      if (status === StatusEnum.SUCCESS) {
        if (obj?.id) {
          dispatch(setProgressStatus(obj.id));
        }
      } else if (status === StatusEnum.PENDING) {
        dispatch(setLoaderStatusMessage(obj?.message ?? 'Syncing...'));
        if (obj?.id) dispatch(setProgressStatus(obj.id));
      } else {
        dispatch(setProcessId(''));
        dispatch(setLoaderState(false));
        dispatch(setLoaderStatus(LoaderStatusEnum.FAIL));
        dispatch(
          setToast({
            id: new Date().getTime(),
            message: 'An error occurred please check your email for more details',
            type: 'error',
            variant: 'Error',
          })
        );
      }
    }
  };

  const handleScheduleGenerationSync = (data: string) => {
    const { status, instituteId } = JSON.parse(data);
    const navigateConst = PRIVATE_NAVIGATION.dataValidation.path;
    if (instituteId === currentInstitute?.value) {
      if (status === StatusEnum.PENDING) {
        dispatch(setLoaderStatusMessage('Syncing Data...'));
        dispatch(setPrimaryStep(events.SCHEDULE_GENERATION_SYNC.id));
        dispatch(setLoaderStatus(LoaderStatusEnum.CREATE));
      } else if (status === StatusEnum.SUCCESS) {
        setNavigateTo(navigateConst);
        dispatch(setLoaderStatusMessage('Syncing completed...'));
        dispatch(setPrimaryStep(events.SCHEDULE_GENERATION_SYNC.id + 1));
        dispatch(setLoaderStatus(LoaderStatusEnum.PENDING));
      } else {
        dispatch(setProcessId(''));
        dispatch(setLoaderState(false));
        dispatch(setLoaderStatus(LoaderStatusEnum.FAIL));
      }
    }
  };

  const handleCiplexCall = (data: string) => {
    const { status, instituteId } = JSON.parse(data);
    if (instituteId === currentInstitute?.value) {
      if (status === StatusEnum.PENDING) {
        dispatch(setLoaderStatus(LoaderStatusEnum.IN_PROGRESS));
        dispatch(setPrimaryStep(events.CIPLEX_CALL.id));
      } else if (status === StatusEnum.SUCCESS) {
        dispatch(setLoaderStatus(LoaderStatusEnum.SUCCESS));
        dispatch(setPrimaryStep(events.CIPLEX_CALL.id + 1));
        dispatch(setLoaderStatusMessage(events.CIPLEX_CALL.message));
      }
    }
  };

  useEffect(() => {
    const socket = connectSocket();
    socket?.on(events.SERVICE.name, (data) => {
      handleSyncingStatus(data, events.SERVICE);
    });
    socket?.on(events.SUBJECT.name, (data) =>
      handleSyncingStatus(data, events.SUBJECT)
    );
    socket?.on(events.TEACHER.name, (data) =>
      handleSyncingStatus(data, events.TEACHER)
    );
    socket?.on(events.STUDENT.name, (data) =>
      handleSyncingStatus(data, events.STUDENT)
    );
    socket?.on(events.CLASSROOM.name, (data) =>
      handleSyncingStatus(data, events.CLASSROOM)
    );
    socket?.on(events.SESSION.name, (data) =>
      handleSyncingStatus(data, events.SESSION)
    );
    socket?.on(events.HOLIDAY.name, (data) =>
      handleSyncingStatus(data, events.HOLIDAY)
    );
    socket?.on(events.SYNC_ALL, handleSyncingStatus);
    socket?.on(events.CUSTOM_FIELDS, handleSyncingStatus);
    socket?.on(events.COMPLETE_SCHEDULE_GENERATION, (data) =>
      handleCompleteProcessOfScheduleGeneration(data)
    );
    socket?.on(events.SCHEDULE_GENERATION_SYNC.name, (data) =>
      handleScheduleGenerationSync(data)
    );
    socket?.on(events.CIPLEX_CALL.name, handleCiplexCall);

    return () => {
      socket?.off(events.SERVICE.name, handleSyncingStatus);
      socket?.off(events.SUBJECT.name, handleSyncingStatus);
      socket?.off(events.TEACHER.name, handleSyncingStatus);
      socket?.off(events.STUDENT.name, handleSyncingStatus);
      socket?.off(events.CLASSROOM.name, handleSyncingStatus);
      socket?.off(events.SESSION.name, handleSyncingStatus);
      socket?.off(events.HOLIDAY.name, handleSyncingStatus);
      socket?.off(events.SYNC_ALL, handleSyncingStatus);
      socket?.off(events.CUSTOM_FIELDS, handleSyncingStatus);
      socket?.off(
        events.COMPLETE_SCHEDULE_GENERATION,
        handleCompleteProcessOfScheduleGeneration
      );
      socket?.off(
        events.SCHEDULE_GENERATION_SYNC.name,
        handleScheduleGenerationSync
      );
      socket?.off(events.CIPLEX_CALL.name, handleCiplexCall);
    };
  }, [currentInstitute?.value]);

  const connectSocket = () => {
    const url = REACT_APP_API_URL;
    const socket = openSocket(url as string, {
      forceNew: true,
      transports: ['websocket'],
      query: {
        token,
      },
    });
    return socket;
  };

  return <></>;
};
export default SocketComponent;
