import BrightmontIcon from 'components/Icon/assets/BrightmontIcon';
import TeachworksIcon from 'components/Icon/assets/TeachworksIcons';
import { LoaderStatusEnum } from 'interfaces/common.interface';
import { useSelector } from 'react-redux';
import {
  getLoaderStatus,
  getProgressStatus,
  getProgressStatusMessage,
} from 'reduxStore/slices/loaderSlice';

export default function Loader() {
  const progressStatus = useSelector(getProgressStatus);
  const message = useSelector(getProgressStatusMessage);
  const loaderStatus = useSelector(getLoaderStatus);
  return (
    <>
      <div className="mt-36 relative">
        <div className="flex items-center justify-between max-w-[444px] mx-auto">
          <div className="w-[120px] h-[120px] border-2 border-primary rounded-[30.67px] flex items-center justify-center">
            <TeachworksIcon />
          </div>
          <div className="flex items-center gap-2">
            {[...Array(5)].map((_, i) => (
              <span
                key={i}
                className="w-[32px] h-[3px] bg-primaryLight-20 rounded-full"
              />
            ))}
          </div>
          <div className="w-[120px] h-[120px] border-2 border-primary rounded-[30.67px] flex items-center justify-center px-2">
            <BrightmontIcon />
          </div>
          <div className="absolute left-1/2 -translate-x-1/2 w-[74px]">
            <img src="/images/folder-loader.gif" alt="" className="w-full" />
          </div>
        </div>
        <p className="mt-[30px] text-sm text-grayText font-normal leading-[17.07px] text-center">
          {message}
        </p>
      </div>

      {loaderStatus !== LoaderStatusEnum.IN_PROGRESS &&
        loaderStatus !== LoaderStatusEnum.SUCCESS && (
          <div className="mt-[50px] w-full max-w-[580px] mx-auto">
            <div className="w-full bg-primaryLight-20 rounded-full h-[6px]">
              <div
                className="bg-primary h-[6px] rounded-full transition-all duration-500 ease-in-out"
                style={{ width: `${(progressStatus * 100) / 7}%` }}
              />
            </div>
            <p className="mt-[30px] text-sm text-grayText font-normal leading-[17.07px] text-center">
              (In Progress - {progressStatus}/7)
            </p>
            <p className="mt-[30px] text-xs text-grayText font-normal leading-[17.07px] text-center">
              The generation of a schedule takes 5-10 minutes. You are welcome to
              close this view and come back when it is ready. You will receive an
              email notification when the new schedule is completed
            </p>
          </div>
        )}
    </>
  );
}
