import './style/Loader.css';

interface LoadersProps {
  className?: string;
}

const Loaders = ({ className }: LoadersProps) => {
  return (
    <span
      className={`animate-spin h-5 w-5 inline-block border-4 border-solid border-gray-300/50 border-l-gray-300 rounded-full ${
        className ?? ''
      }`}
    />
  );
};

export default Loaders;
