// ** Packages **
// import { useSelector } from "react-redux";
// import { Navigate, useLocation } from "react-router-dom";

// ** Components **
import Layout from 'components/Layout';

// ** Redux **
// import { RootStateType } from "redux/store";

// ** Constants **
// import { PUBLIC_NAVIGATION } from "constants/navigation.constant";
import SiteLoader from 'components/Loaders/SiteLoader';
import { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { currentPageCount } from 'reduxStore/slices/paginationSlice';
import { getAuthToken } from 'reduxStore/slices/tokenSlice';

type Props = {
  children: JSX.Element;
};

const RequiresAuth = (props: Props) => {
  const { children } = props;
  const authToken = useSelector(getAuthToken);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(currentPageCount({ currentPage: 1 }));
  }, [location]);

  if (!authToken) {
    return <Navigate to="/auth/login" />;
  }

  // ** Hooks **
  // const location = useLocation();
  // const authData = useSelector((state: RootStateType) => state.auth);

  // ** Custom Hooks **
  // const { isAuthenticated } = authData;

  // // ** Not Logged In **
  // if (!isAuthenticated) {
  //   return <Navigate to={PUBLIC_NAVIGATION.login} state={{ from: location }} />;
  // }

  return (
    <Layout>
      <Suspense fallback={<SiteLoader />}>{children}</Suspense>
    </Layout>
  );
};

export default RequiresAuth;
